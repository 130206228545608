<script setup lang="ts">
defineProps<{
  feature: string;
  plan: string;
  description: string;
}>();

const modal = useModal();

const upgrade = () => {
  modal.close();
  return navigateTo('/account/billing');
};

const cancel = () => {
  modal.close();
  return navigateTo('/');
};

// review and refine your spell and agent
//           workflows.
</script>
<template>
  <UModal prevent-close>
    <UCard
      :ui="{
        ring: '',
        divide: '',
      }"
    >
      <div class="flex flex-col items-center gap-4">
        <UIcon name="i-ph-crown" size="xl" class="text-5xl text-primary-500" />
        <p class="main-title">
          {{ feature }} {{ feature.slice(-1) === 's' ? 'are' : 'is' }} for
          {{ plan }}s
        </p>
        <p class="dimmed text-center">
          This feature is restricted to {{ plan }} accounts. Upgrade your
          account to {{ description }}
        </p>
        <div class="flex gap-4 w-full mt-4">
          <UButton size="xl" color="white" @click="cancel">Cancel</UButton>
          <UButton size="xl" @click="upgrade">Upgrade to {{ plan }}</UButton>
        </div>
      </div>
    </UCard>
  </UModal>
</template>
